<template>
  <div class="h-full min-h-full px-4 pt-16 bg-white lg:px-16">
    <div class="flex justify-between">
      <h1 class="text-4xl font-normal font-poppins">API Tokens</h1>
      <Button variant="empty" class="-ml-64"
        ><lock-icon class="inline-block w-4 h-4 mr-2 text-gray-500" />
        System Administrator
      </Button>
    </div>
    <p class="mt-6 font-poppins">
      Manage credentials for accessing the application api.
    </p>
    <AuthTokensTable />
  </div>
</template>

<script>
import AuthTokensTable from '@/components/tables/AuthTokensTable.vue';

export default {
  components: {
    AuthTokensTable,
  },
  setup() {},
};
</script>
