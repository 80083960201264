<template>
  <Form
    :validation-schema="validationSchema"
    action="/tokens"
    @success="handleSuccess"
  >
    <template #default="{ meta }">
      <div class="flex space-x-8">
        <Field name="name" label="Name" placeholder="Name" class="w-96" />
        <Field component="select" name="scopes" label="Scopes">
          <option selected disabled value="">Select Scope</option>
          <option
            v-for="(scope, index) in scopes"
            :key="index"
            :value="scope.action"
          >
            {{ scope.label }}
          </option>
        </Field>
        <Field
          component="select"
          name="expiresAt"
          label="Expiration"
          :modelValue="null"
        >
          <option value="">Never</option>
        </Field>
      </div>
      <Button
        :variant="meta.valid && meta.dirty ? 'primary' : 'gray'"
        :disabled="!meta.valid && meta.dirty"
        class="w-48 mt-8 mb-12"
        >Create Token <plus-icon class="inline-block w-3 h-3 ml-2 -mt-1"
      /></Button>
    </template>
  </Form>
  <Modal id="token-success-modal" title="API Token Created">
    <template #default="{ context }">
      <p class="font-semibold font-poppins mb-4">
        Copy the token below to a secure location.<br />
        You won't be able to see it again.<br />
        Keep it secret. Keep it safe. &#x1f9d9;
      </p>
      <Field
        component="textarea"
        readonly
        :modelValue="context.options.token"
      />
    </template>
  </Modal>
</template>

<script>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { object, string } from 'yup';
import { useModal, useState } from '@/helpers/composables';

export default {
  emits: ['success'],
  setup(props, { emit }) {
    const store = useStore();
    const [scopes, setScopes] = useState([]);
    const { open: openSuccessModal } = useModal('token-success-modal');

    onMounted(async () => {
      const { data } = await store.dispatch('get', {
        action: '/tokens/scopes',
      });
      setScopes(data);
    });

    const validationSchema = object({
      name: string().required(),
      scopes: string().required(),
      expiresAt: string().optional().nullable(true),
    });

    function handleSuccess(event) {
      emit('success', event);
      successModal(event);
    }

    function successModal({ data: { token } }) {
      openSuccessModal({
        token,
      });
    }

    return {
      scopes,
      validationSchema,
      handleSuccess,
    };
  },
};
</script>
